<template>
  <modal v-on="$attrs">
    <template #body>
      <p v-if="isNewPatient" class="he-ui-leave-register-modal-title text-uppercase">{{ $t('main.exit_register') }}</p>
      <p v-if="!isNewPatient" class="he-ui-leave-register-modal-title text-uppercase">{{ $t('main.exit_identification') }}</p>
      <p v-if="isNewPatient" class="he-ui-leave-register-modal-subtitle">{{ $t('main.exit_register_question') }}</p>
      <p v-if="!isNewPatient" class="he-ui-leave-register-modal-subtitle">{{ $t('main.exit_identification_question') }}</p>
      <div class="he-ui-leave-register-modal-image mb-2">
        <img
          src="../assets/patient-error.png"
          alt="image-error" />
      </div>
      <div class="d-flex gap-3">
        <Button
          v-for="button, index in buttons"
          :key="index"
          class="mt-4"
          :template="button.template"
          rounded
          uppercase
          :text="button.text"
          :onClick="button.handler" />
      </div>
    </template>
  </modal>
</template>

<script>
import Button from '@/components/MainButton'
import Modal from './Modal.vue'

export default {
  components: {
    Button,
    Modal
  },
  props: {
    buttons: {
      type: Object,
      required: true
    },
    isNewPatient: {
      type: Boolean,
      required: true,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.he-ui-leave-register-modal-title {
  font-weight: bold;
  font-size: 1.4rem;
}
.he-ui-leave-register-modal-subtitle {
  font-size: 1.1rem;
  color: $grey-dark-color;
}
.he-ui-leave-register-modal-image{
  width: 140px;
  height: 140px;
   img {
    max-width: 100%;
   }
}
</style>
